import React,{useState} from "react";
import logo from '../image/logo.png';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Error from "../components/Alerts/Error";
const ResetPassword=()=>{
  const navigate=useNavigate()
  const [email,setEmail]=useState("")
  const [passcode,setPasscode]=useState("")
  const [newPasscode,setNewPasscode]=useState("")
  const [confirmPasscode,setConfirmPassword]=useState("")
  const [errors,setErrors]=useState(["","","",""])
  const [error,setError]=useState(false)
  const [errorMessage,setErrorMessage]=useState('')
  const numRegex= /^[0-9]+$/
  const emailRegex=/^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const submit=async(e)=>{
    e.preventDefault()
     if(validation()){
        try{
            await axios.post(`${process.env.REACT_APP_APISTAFF}/auth/reset/passcode/initial`,
                {
                    email: email,
                    oldPasscode:passcode,
                    newPasscode:newPasscode
                }
                )
                navigate('/login')
        }
        catch(e){
            setError(true)
            if(e.code==="ERR_NETWORK"){
                setErrorMessage("Check on your network")
                return
            }
            if(e.code==="ERR_BAD_RESPONSE"){
                setErrorMessage("Wrong email or passcode")
                return
            }
          console.log(e.code)
        }
     }
  }
  const validation=()=>{
    let error=["","",""]
    let valid=true
    if(email.length===0){
        error[0]="*Email address is empty"
        valid=false
    }
    if(email.length!==0){
        if(!emailRegex.test(email)){
          error[0]="*Email address incorrect"
          valid=false
        }
      }
      const passcodeValidate=validatePasscode(passcode)
      valid=passcodeValidate.valid
      error[1]=passcodeValidate.error
      const newPasscodeValidate=validatePasscode(newPasscode)
      valid=newPasscodeValidate.valid
      error[2]=newPasscodeValidate.error
      const confirmPasscodeValidate=validatePasscode(confirmPasscode)
      valid=confirmPasscodeValidate.valid
      error[3]=confirmPasscodeValidate.error
    if(confirmPasscode!==newPasscode){
        error[3]="*Passcode and confirm passcode not same"
        valid=false
    }
    setErrors(error)
return valid
}
const cancelClick=()=>{
  setError(false)
}
const validatePasscode=(value)=>{
    let valid=true
    let error=""
    if(value.length===0){
        error="*Passcode is empty"
        valid=false
    }
    if(value.length>0&&value.length!==5){
        error="*Passcode required should be five numbers"
        valid=false
    }
    if(value.length===5){
        const checkIdValid=numRegex.test(value)
        if(!checkIdValid){
            error="* Passcode required, is only a number"
            valid=false
        }
        if(checkIdValid){
            error=""
        }
    }
    return {valid,error}
}

return (
    <div className="login2-main">
        <div className="lg-pt-1">
        <div className="slogan-body">
          <h2 className="slogan-txt">Unlock exceptional culinary experience with J-GAS</h2>
        </div>
        </div>
        <div className="lg-pt-2">
         <div className="login-form-bd">
            <div className="logo2-convas">
                <div className="logo-div-">
                  <img src={logo}  alt="logo" className="logo2"/>
                </div>
            </div>
            <h4 className="salutation">Welcome</h4>
            {error&&(<Error onCancel={cancelClick} message={errorMessage}/>)}
            <form className="form-login-1" onSubmit={submit}>
                  <div className="log2-div mr-t">
                    <h3 className="sign-in">Reset you passcode</h3>
                  </div>
                  <div className="log2-div">
                    <input type="text" className="input-log2" onChange={(e)=>setEmail(e.target.value)} placeholder="Email Address"/>
                    <p className="error">{errors[0]}</p>
                  </div>
                  <div className="log2-div mr-t">
                    <input type="password" className="input-log2"  onChange={(e)=>setPasscode(e.target.value)} placeholder="Please Enter passcode"/>
                    <p className="error">{errors[1]}</p>
                  </div>
                  <div className="log2-div mr-t">
                    <input type="password" className="input-log2"  onChange={(e)=>setNewPasscode(e.target.value)} placeholder="Please New passcode"/>
                    <p className="error">{errors[2]}</p>
                  </div>
                  <div className="log2-div mr-t">
                    <input type="password" className="input-log2"  onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Please Confirm passcode"/>
                    <p className="error">{errors[3]}</p>
                  </div>
                  <div className="log2-div mr-t">
                     <button className="signin-btn">Submit</button>
                   </div>
            </form>
         </div>
        </div>
    </div>
)
}
export default ResetPassword
