import React from "react";
import Navigation from "./Navigation";
import { Outlet } from "react-router-dom";
const MainLayout=()=>{
return (
    <div className='main-section'>
      <Navigation/>
      <div className='main-content'>
        <Outlet/>
      </div>
  </div>
)
}
export default MainLayout