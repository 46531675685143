import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios"
import { useNavigate } from "react-router-dom"
import DataTable from "react-data-table-component"
const Prices=()=>{
   const { shopId } = useParams();
//    const token=useSelector(state=>state.auth.accessToken)
   const [prices,setPrices]=useState([])
   const navigate=useNavigate()
   const columns=[
    {
        name: 'No:',
        selector: row => row.id,
        sortable: true,
    },
    {
        name: 'Product ID:',
        selector: row => row.productId,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Price (Ksh)',
        selector: row => row.price,
        sortable: true,
    },
]
const toProds=()=>{
    navigate(`/prodprice/${shopId}`)
}


    useEffect(()=>{
        async function getShopPrices(){
            try{
                //
                ///products/prices?shop=${shopId}
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/`)
                //,{ headers: {"Authorization" : `Bearer ${token}`} }
                const mapp_data=data.prices.map((price,index)=>{
                 return{
                    id:index+1,
                    productId:price.product.productId,
                    name:price.product.productName,
                    price:price.productPrice
                 }
                })
                setPrices(mapp_data)
            }
            catch(e){
    
            }

        }
        getShopPrices()
    },[])
return(
    <div className="body-page">
    <div className="actions-products">
      <div><h3 className="tb-title">Prices</h3></div>
      <div><button className="action-btn" onClick={toProds}>Add Price</button></div>
    </div>
  <DataTable
  columns={columns}
  data={prices}
  pagination
/> 

</div>
)
}
export default Prices