import React,{useMemo} from "react";
import { GoogleMap,useLoadScript } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { app } from "../store/appSlice";
const Locate=()=>{
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLEMAPKEY}`,
      });
      const center = useMemo(() => ({ lat: -1.302061475315885, lng: 36.881381109929194 }), []);
      return(
        <div className="map-wrapper">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
        mapContainerClassName="map-component"
        onClick={ev=>{
            dispatch(app.setGeocode({lat:ev.latLng.lat(),lng:ev.latLng.lng()}))
            navigate('/add/shop/0/0')
        }}
        center={center}
        zoom={12}
      />
      )}
    </div>
      )
// center
}
export default Locate

