import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom"
import { useSelector,useDispatch } from "react-redux"
import axios from "axios";
import { vendor } from "../store/vendorSlice";
import Wait from "../components/Alerts/Wait";
const VendorRegister=()=>{
    const [locations,setLocations]=useState([])
    const [location,setLocation]=useState("")
    const [alertTxt,setAlertText]=useState("")
    const [color,setColor]=useState('alert-cl')
    const [registrationSuccess,setRegistrationSuccess]=useState(false)
    const navigate=useNavigate()
    const vendor1=useSelector(state=>state.vendor.staffData)
    const dispatch=useDispatch()
    const onSubmit=async(e)=>{
        e.preventDefault()
        try{
            const requestBody={
                contactAlternative: vendor1.AltContact,
                firstName: vendor1.firstName,
                lastName: vendor1.lastName,
                registrationType: "Id",
                locationId: location, //shop id
                contactDefault: vendor1.contact,
                nationalRegistration: vendor1.ID,
                email: vendor1.email,
                gender:vendor1.gender
            }

            const staffBody={
                email:requestBody.email,
                ID:requestBody.nationalRegistration,
                contact:requestBody.contactDefault,
                AltContact:requestBody.contactAlternative,
                firstName:requestBody.firstName,
                lastName:requestBody.lastName,
                gender:requestBody.gender,
                role:'VS'
            }
            await axios.post(`${process.env.REACT_APP_APISTAFF}/auth/register`,staffBody)
        const {data}=await axios.post(`${process.env.REACT_APP_APIURL}/vendorRegistration`,requestBody)
        if(data.resultCode!==0){
            setAlertText(data.resultMessage)
            setColor('alert-error')
            return
        }
        dispatch(vendor.resetUser())
        setRegistrationSuccess(true)
        setAlertText("Vendor registered successfully")
        setColor('alert-cl')
        }
        catch(e){
            const errorMessage='Error in vendor registration, check with admin'
            if(e.response===undefined){
                setAlertText(errorMessage)
                setColor('alert-error')
                return
            }
            if(e.response.status===400){
                setAlertText(e.response.data.message)
                setColor('alert-error')
                return
            }
            setAlertText(errorMessage)
            setColor('alert-error')
        }
    }
    useEffect(()=>{
        function checkStaffData(){
            if(Object.keys(vendor1).length===0){
                navigate('/customers')
            }
        }
     async function getLocations() {
        try{
            const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/allLocations`)
            const locations=data.map((item)=>item.locationId)
            setLocations(locations)
            setLocation(locations[0])
        }
        catch(e){
              setLocations([])
        }
     }
     checkStaffData()
     getLocations()
    },[navigate,vendor1])
    const onWaitCancel=()=>{
        setAlertText("")
        setColor('alert-cl')
        if(registrationSuccess===true){
            setRegistrationSuccess(false)
            navigate('/')
            return
        }
    }
    return(<div className="body-page">
        <form className="user-form-create" onSubmit={onSubmit}>
             <div className="sn-item-input">
             {alertTxt.length>0&&( <Wait title={alertTxt} color={color} onCancel={onWaitCancel}/>)}
            <label htmlFor='name' className='form-label'>Location:</label>
            <select onChange={(e)=>{setLocation(e.target.value)}} className="input-value" >
                {locations.map((option, index) => {
                        return (
                            <option key={index}>
                                {option}
                            </option>
                        );
                    })}
                </select>
           </div>
           <div className="sn-item-input">
               <button className="form-btn">Submit</button>
           </div>
        </form>
       </div>)
}
export default VendorRegister