import React from "react";
const Error=({onCancel,message})=>{
return(<div className="pop">
<div className="pop-item">
 <p className="error-color">{message}</p>
</div>
<div className="pop-item">
<p className="error-color error-txt" onClick={onCancel}>X</p>
</div>
</div>)
}
export default Error