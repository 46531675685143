import React,{useState,useEffect} from "react";
//,useMemo
import { useParams,useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { app } from "../store/appSlice";
import Orders from "../components/Orders";
import { fetchOrders } from "../utils/fetchOrders";
const ShopOrders=()=>{
    const { shopId }=useParams()
    const [filteredOrders,setFilteredOrders]=useState([])
     const dispatch=useDispatch()
    // const [orders,setOrders]=useState([])
    const navigate=useNavigate()
    const OrderItems=prop=>{
        return(<button onClick={()=>{
          dispatch(app.setOrder(prop.order))
          navigate(`/order/items/${prop.id}`)
        }} className="tb-btns">
          Items
        </button>)
      }
    // const filteredData = useMemo(() => {
    //     return orders.map((item)=>{
    //         return{...item,items:<OrderItems id={item.orderNo} order={item}/>}
    //     })
    //    }, [orders, shopId]);
       useEffect(()=>{
      async  function getOrders(){
       const data=await fetchOrders(shopId)
       const orderData= data.map((item)=>{
                return{...item,items:<OrderItems id={item.orderNo} order={item}/>}
            })
       setFilteredOrders(orderData)
        }
        getOrders()
       },[shopId])
   return(
       <Orders orders={filteredOrders}/>
    )
}
export default ShopOrders