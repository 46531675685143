
import React,{useEffect,useState,useCallback} from "react";
import { useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import axios from "axios";
import { locateOnMap } from "../utils/locateOnMap";
import { CiExport } from "react-icons/ci";
import { createCsv } from "../utils/createCsv";
// import DiscountModel from "../components/Alerts/DiscountModels";
// import { IoSettingsSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const Customers=()=>{
    const role=useSelector(state=>state.auth.role)
    // const token=useSelector(state=>state.auth.accessToken)
    const [customers,setCustomers]=useState([])
    const [listCustomers,setListCustomers]=useState([])
    const [refreshCustomer,setRefreshCustomer]=useState(false)
    const navigate=useNavigate()
    const Locate=({latitude,longitude})=>{
        return(<p onClick={async()=>{
            locateOnMap(latitude,longitude)
        }} className="locator">
            View Location
        </p>)
      }
      const Discount=({phoneNumber,isStaff})=>{
        const [format,setFormat]=useState({style:`tb-btn-stl`,label:`No`})
        return(
        <>
        {isStaff===0&&<button onClick={()=>{
           navigate(`/discount/staff/${phoneNumber}`)
        }} className={format.style} onMouseOver={()=>setFormat({style:`tb-btn-stl-bolder`,label:`Convert`})}
         onMouseOut={()=>setFormat({style:`tb-btn-stl`,label:`No`})}>
          {format.label}
        </button>}
        {isStaff===1&&<button className="tb-btn-stl" disabled>
          Yes
        </button>}
        </>)
      }
      const SpecialPrice=({phoneNumber,flag,toggleRefresh})=>{
        const [format,setFormat]=useState({style:`tb-btn-stl`,label:`${flag===1?"Withdraw":"Offer"}`})
        return(
        <>
        <button onClick={async()=>{
            try{
          const updateFlag=flag===1?0:1;
            const { data } =await axios.put(`${process.env.REACT_APP_APIURL}/updateSpecialPurchasePrice`,{
            phoneNumber,
            specialPrice: updateFlag
           }) 
           if(data.resultCode===0){
             toggleRefresh()
            return
           }
            }
            catch(e){

            }
        }} className={format.style} onMouseOver={()=>setFormat({...format,style:`tb-btn-stl-bolder`})}
         onMouseOut={()=>setFormat({...format,style:`tb-btn-stl`})}>
          {format.label}
        </button>
        </>)
      }
      const SalesRole=({data})=>{
        const [buttonLc,setButtonLc]=useState({
            data:data!==undefined?data:undefined,
            style:`tb-btn-stl`,
            label:data!==undefined?data.role:"None"
            })
        return(
            <>
            {data===undefined&&<button className={buttonLc.style} disabled>
               {buttonLc.label}
            </button>
            }
            {data!==undefined&&<button className={buttonLc.style} onMouseOver={
                ()=>setButtonLc({...buttonLc,label:"Change",style:`tb-btn-stl-bolder`})} 
            onMouseOut={()=>setButtonLc({...buttonLc,label:data.role,style:`tb-btn-stl`})}
            onClick={()=>navigate(`/sales/role/${data.phoneNumber}`)}>
                {buttonLc.label}
            </button>}
            </>
        )
      }
 const exportOrders=()=>{
    const csvCustomers=customers.filter((item)=>{
        const itemProp=item
        delete itemProp.locate
        delete itemProp.staff
        return itemProp
    })
        const keys = Object.keys(csvCustomers[0]).filter((key)=>key!=="items")
        var csvBody=keys.map((ky)=>{
            return `${ky}`
        }).join(",")+"\n"
        csvBody=csvBody+csvCustomers.map((row) => keys.map((k) => {
            return `"${row[k]}"`
        }).join(",")).join("\n")
       createCsv(csvBody,"jgas_app_customers.csv")
    }
    const updateRefresh=useCallback(() => {
        const changeStatus=refreshCustomer===true?false:true
        setRefreshCustomer(changeStatus)
      }, [refreshCustomer]);
    const defaultColumn=[
         {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            wrap:true
        },
        {
            name: 'Contact',
            selector: row => row.contact,
            sortable: true,
        },
        {
            name: 'IdNo',
            selector: row => row.idNo,
            sortable: true,
        },
        {
            name: 'Locate',
            selector: row => row.locate,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Discount',
            selector: row => row.discount,
            sortable: true,
            wrap:true,
        }
    ]
    const columns=role==="ADMIN"?[...defaultColumn,
        {
            name: 'Sales Role',
            selector: row => row.salesrole,
            sortable: false,
            wrap:false,
        },
        {
        name: 'IS STAFF',
        selector: row => row.staff,
        sortable: true,
        wrap:true,

    },
    {
        name: 'Offers',
        selector: row => row.special,
        sortable: true,
        wrap:true,
    }

]:defaultColumn
    useEffect(()=>{
        const getCustomers=async()=>{
            try{
                const responseDiscount=await axios.get(`${process.env.REACT_APP_APIURL}/activeDiscount/NAIROBI`)
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/allRegistrations`)
                const userRoles=await axios.get(`${process.env.REACT_APP_APIURL}/allUsers`)
                const mappedUsers=data.map((user,index)=>{
                    return{
                        id:index+1,
                        Name:user.firstName+" "+user.lastName,
                        contact:user.phoneNumber===null?"":user.phoneNumber,
                        email:user.emailAddress===null?"":user.emailAddress,
                        discount: checkDiscount(user.isStaff,responseDiscount.data.discountAmount),
                        idNo:formatStr(user.idNumber),
                        alternativePhone:user.alternativePhone===null?"":user.alternativePhone,
                        special:<SpecialPrice phoneNumber={user.phoneNumber} flag={user.specialPurchasePrice} toggleRefresh={updateRefresh}/>, 
                        staff:<Discount phoneNumber={user.phoneNumber} isStaff={user.isStaff}/>,
                        salesrole:<SalesRole data={userRoles.data.find((item)=>item.phoneNumber===user.phoneNumber)}/>,
                        locate:<Locate latitude={user.latitude} longitude={user.longitude}/>
                    }
                });
                setCustomers(mappedUsers)
                setListCustomers(mappedUsers)
            }
            catch(e){
                setListCustomers([])
                setCustomers([])
            }
        }
        getCustomers()
    },[updateRefresh])
    const formatStr=(value)=>{
        if(value===null){
            return "Unavailable"
        }
        return value
    }
    const checkDiscount=(isStaff,value)=>{
        return isStaff===1?"Ksh. "+value.toLocaleString()+'.0':"Unavailable"
    }
    const search=(e)=>{
        const searchStr=e.target.value
        console.log('Search ',searchStr)
        if(customers.length>0){
            const searched=customers.filter((item)=>{
                const checker=searcMatch(item.Name,searchStr)|| searcMatch(item.email,searchStr)
                ||searcMatch(item.contact,searchStr)
               //||searcMatch(item.alternativePhone,searchStr)||
                // searcMatch(item.idNo,searchStr)
              return checker
            }
            )
            setListCustomers(searched)	
        }
    }
    const searcMatch=(value,search)=>{
    return value.toLowerCase().includes(search.toLowerCase())
    }
 
return (<div className="body-page">
     <div className="dt-actions dashboard-bg">
		   <div className="dash-item">
             <h3 className="tb-dashboard">Customers</h3>
             <div className="search customer-sp">
					<input type="text" className="search-orders" onChange={search}  placeholder="Search here ..." />
			   </div>
			</div>
			<div className="dash-item">
               {customers.length>0&&(<div className="icon-holder mr-rt-2">
			   <CiExport onClick={exportOrders}/>
			</div>)}
			</div>
		</div>
       <DataTable
			columns={columns}
			data={listCustomers}
            pagination
		/> 

</div>)
}
export default Customers
