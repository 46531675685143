import React,{useEffect, useState} from "react";
import { useSelector} from "react-redux";
import Product from "../components/Product";
import { useDispatch } from "react-redux";
import { app } from "../store/appSlice";
import { useNavigate } from "react-router-dom";
import { fetchProducts } from "../utils/fetchProduct";
const Products=()=>{
    const [products,setProducts]=useState([])
    const role=useSelector(state=>state.auth.role)
    const dispatch=useDispatch()
   const navigate=useNavigate()
    //   <img src={`http://localhost:3000/${meal.image}`} alt={meal.name} />
    const createProduct=()=>{
       navigate('/add/product/0/0')
    }
    useEffect(()=>{
        async function getProducts(){
       const products=await fetchProducts()
       dispatch(app.setProducts(products))
       setProducts(products)
     }
     getProducts()
     })
     return(
         <div className="body-page">
            <div className="actions-tb">
                <div><h3 className="tb-title">Products</h3></div>
                {role==="ADMIN" && <div><button className="action-btn" onClick={createProduct}>Add Product</button></div>}
            </div>
            <div className="products-items">
              {products.map((product) => (
            <Product product={product} key={product.id}/>
      ))}
            </div>
    </div>
)
}
export default Products