import React,{useState,useMemo,useEffect} from "react";
import { useParams,useNavigate } from "react-router-dom"
import { useSelector} from "react-redux";
import axios from "axios";
const AddProductPrice=()=>{
    const { shopId } = useParams();
    const { productId } = useParams();
    const navigate=useNavigate()
    const products=useSelector(state=>state.app.products)
    const [productName,setProductName]=useState('')
    const [description,setDescription]=useState('')
    const [productNo,setProductId]=useState('')
    const [productPrice,setProductPrice]=useState(0)
    const [priceError,setProductError]=useState('')
    // <p className="error">{error[3]}</p>
    const filteredData = useMemo(() => {
        return products.find((item)=>item.productId===productId)
        }, [products, productId]);
        useEffect(()=>{
            setProductName(filteredData.name)
            setDescription(filteredData.description)
            setProductId(filteredData.productId)
        },[filteredData])
        const onSubmit=async(e)=>{
            e.preventDefault()
            if(productPrice!==0){
                setProductError('')
                try{
                    const jsonBody={
                        locationId: shopId,
                        productId: productId,
                        price: productPrice
                    }
                    const {data}=await axios.post(`${process.env.REACT_APP_APIURL}/createPrice`,jsonBody)
                    navigate(`/shop/details/${shopId}`)
                    console.log('Shop id',data)  
                }
                catch(e){
                    console.log('Items are here ',e)
                    setProductError('Error in trying stocking')
                }
            }
            else{
                setProductError('Please provide the price')
            }
        }
return(
    <div>
        <form onSubmit={onSubmit} className="user-form-create">
        <div><h3 className="tb-title title-mg">New Pricing</h3></div>
            <div className="sn-item-input">
                <label htmlFor='name' className='form-label'>Product Name:</label>
                <input type="text" className="input-value input-txt" id="name" value={productName} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='product_id' className='form-label'>Product ID:</label>
                <input type="text" className="input-value input-txt" id="product_id" value={productNo} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='product_desc' className='form-label'>Product Description:</label>
                <input type="text" className="input-value input-txt"  id="product_desc" value={description} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='product_desc' className='form-label'>Product Price:</label>
                <input type="number" className="input-value input-txt" id="product_desc" onChange={(e)=>{setProductPrice(e.target.value)}}/>
                <p className="error">{priceError}</p>
            </div>
            <div className="sn-item-input">
                <button className="form-btn">Add</button>
            </div>
        </form>

    </div>
)
}
export default AddProductPrice