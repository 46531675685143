import React,{useState} from "react"
import logo from '../image/logo.png';
import axios from "axios";
import '../css/login.css'
//,useSelector
import { useDispatch } from "react-redux";
import { auth } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import Error from "../components/Alerts/Error";
const Login=()=>{
    const [email,setEmail]=useState('')
    const [passcode,setPasscode]=useState('')
    const [valid,setValid]=useState(['',''])
    const [errorMessage,setErrorMessage]=useState('')
    const [error,setError]=useState(false)
    const dispatch=useDispatch()
    const navigate=useNavigate()
   // const logins=useSelector(state=>state.auth.accessToken)
    
   
    //(\+254|254|0|^){1}[ ]?([7][0-9]|[1][0-1]){1}[0-9]{1}[ ]?[0-9]{6}
    const validations=()=>{
        let error=["",""]
        let valid=true
        if(email.length===0){
            error[0]="*Email address cann't be empty"
            valid=false
        }
        if(passcode.length===0){
            error[1]="*Passcode cann't be empty"
            valid=false
        }
        if(passcode.length>5){
            error[1]="*Passcode must be 5 numbers"
            valid=false
        }
        setValid(error)
        return valid
    }

    const onSubmit=async(e)=>{
        e.preventDefault()
        if(validations()){
            try{
                const {data}=await axios.post(`${process.env.REACT_APP_APISTAFF}/auth/login`,{
                    email:email,
                    passcode:passcode
                    })
                        dispatch(auth.login({token:data.token,refresh:data.refreshToken,email:email,role:data.role,lastName:data.lastName}))
                        navigate('/')
                    setErrorMessage("Failed credentials")
            }
            catch(e){
                setError(true)
                setErrorMessage("Check on email and passcode")
                console.log("Exception ",e.response.data)
            }
        }
    }
    const cancelClick=()=>{
        setError(false)
    }
return(<div className="login-main">
    <div className="middle-body">
        <div className="top-head">
          <img src={logo}  alt="logo" className="logo-login-main"/>
        </div>
        {error&&(<Error onCancel={cancelClick} message={errorMessage}/>)}
        <form onSubmit={onSubmit} className="login-form">
           <div className="title-div">
             <h3 className="login-title">Login here</h3>
            </div>
            <div className="input-dv">
               <label htmlFor='name' className='form-label'>Email:</label>
               <input type="email" className="input-fv" onChange={(e)=>setEmail(e.target.value)}/>
               <p className="error">{valid[0]}</p>
            </div>
            <div className="input-dv">
               <label htmlFor='name' className='form-label'>Passcode:</label>
               <input type="password" max={5} className="input-fv" onChange={(e)=>setPasscode(e.target.value)}/>
               <p className="error">{valid[1]}</p>
            </div>
            <div className="input-dv">
              <button className="login-btn">Submit</button>
            </div>
        </form>
    </div>
</div>)
}
export default Login