import React,{useState,useEffect,useMemo} from "react";
import { useSelector} from "react-redux";
import { useParams,useNavigate } from "react-router-dom"
import DataTable from "react-data-table-component"
const ProductPrice=()=>{
    const products=useSelector(state=>state.app.products)
    const navigate=useNavigate()
    const { shopId } = useParams();
    const [prods,setProds]=useState([])
    const ProductSelect=prop=>{
        return(<button onClick={()=>{
            navigate(`/product/${shopId}/${prop.product}`)
        }} className="tb-btns">
            Pick
        </button>)
    }
    const filteredData = useMemo(() => {
       return products.map((item)=>{
            return{
                ...item,addProduct:<ProductSelect product={item.productId}/>
            }
        })
      
       }, [products]);
       useEffect(()=>{
        setProds(filteredData)
       },[filteredData])
    const columns=[
        {
            name: 'No:',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'ID',
            selector: row => row.productId,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Add Price',
            selector: row => row.addProduct,
            sortable: true,
        }
    ]
    ///prodprice/:shopId
return(
    <div className="body-page">
    <div className="actions-products">
      <div><h3 className="tb-title">Product Selection</h3></div>
    </div>
  <DataTable
  columns={columns}
  data={prods}
  pagination
/> 

</div>
)
}
export default ProductPrice