import React from "react"
import MainLayout from "./MainLayout"
import SideLayout from "./SideLayout"

const GeneralLayout=()=>{
return(
 <div className='home-main'>
    <SideLayout/>
    <MainLayout/>
  </div>
)
}
export default GeneralLayout