import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./authSlice";
import AppSlice from "./appSlice";
import vendorSlice from "./vendorSlice";
const store=configureStore({
    reducer:{
        auth:AuthSlice.reducer,
        app:AppSlice.reducer,
        vendor:vendorSlice.reducer
    }
})
export default store