import React,{useEffect,useState} from "react";
import {
    BarChart,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Bar
  } from "recharts";
  import axios from "axios";
  import { formatGraphDate } from "../utils/graphDate";
const ChartsOrders=({delivered,canceled,pending,enroute})=>{
  const [dailyRevenue,setDailyRevenue]=useState([])
  const [orderCountDaily,setOrderCountDaily]=useState([])

useEffect(()=>{
 async function getRevenuePerDay(){
  try{ 
    const { data }=await axios.get(`${process.env.REACT_APP_JGasReporting}/findOrdersRevenuePerDay`)
    const revenueDays=data.length>7?data.splice(-7):data
    const chartData=revenueDays.map((item)=>{
      return{
        name:formatGraphDate(item.orderDate),
        revenue:item.total
      }
    })
    setDailyRevenue(chartData)
    }
    catch(e){
      setDailyRevenue([])
  }
  }
  getRevenuePerDay()
},[])

useEffect(()=>{
  async function getOrdersPerDay(){
   try{ 
     const { data }=await axios.get(`${process.env.REACT_APP_JGasReporting}/findOrdersCountPerDay`)
     const orderPerDay=data.length>7?data.splice(-7):data
     const chartData=orderPerDay.map((item)=>{
       return{
         name:formatGraphDate(item.orderDate),
         orders:item.ordersCount
       }
     })
     setOrderCountDaily(chartData)
     }
     catch(e){
       setDailyRevenue([])
   }
   }
   getOrdersPerDay()
 },[])
          /*     {
          name: "15-03",
          revenue: 4000,
          amt: 2400
        }, 
        
            {
          name: "21-03",
          orders: 40,
         
          amt: 2400
        },
        
        */
return <>
    <div className="simple-dashboard-items">
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Orders Delivered</h5>
            <h5 className="value-items">{delivered}</h5>
          </div>
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Orders Cancelled</h5>
            <h5 className="value-items">{canceled}</h5>
          </div>
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Orders Pending</h5>
            <h5 className="value-items">{pending}</h5>
          </div>
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Rider Enroute</h5>
            <h5 className="value-items">{enroute}</h5>
          </div>
       </div>
       <div className="dashboard-mb">
       <h3 className="simple-dashbord-title">
          Charts 
        </h3>
       </div>
       <div className="charts-view">
         <div className="charts-view-item">
          <h4 className="chart-title">{`Revenue (Ksh) for the last 7 days entries`}</h4>
         <LineChart
            width={480}
            height={280}
            data={dailyRevenue}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
          <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis/>
            {/* label={{ value: 'Revenue in Ksh', angle: -90, position: 'insideLeft' }}  */}
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="revenue"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="dc" stroke="#82ca9d" /> */}
          </LineChart>
         </div>
         <div className="charts-view-item">
         <h4 className="chart-title">Orders Count for the last 7 days entries</h4>
         <BarChart width={480} height={280} data={orderCountDaily}   margin={{
              top: 2,
              right: 5,
              left: 5,
              bottom: 2
            }}>
          <XAxis dataKey="name" stroke="#8884d8" />
          <YAxis label={{ value: 'Orders Made', angle: -90, position: 'insideLeft' }} />
           <Tooltip wrapperStyle={{ width: 50, backgroundColor: '#ccc' }} />
           <Legend />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
          <Bar dataKey="orders" fill="#8884d8" barSize={30} />
        </BarChart>
         </div>
       </div>
</>
}
export default ChartsOrders