
import React,{ useState,useEffect,useRef } from "react";
import DataTable from "react-data-table-component";
import { createCsv } from "../utils/createCsv";
import { CiExport } from "react-icons/ci";
import { FiFilter } from "react-icons/fi";
import { TbFilterOff } from "react-icons/tb";

const Orders=props=>{
	const [orders,setOrders]=useState([])
	const [runOrders,setRunOrder]=useState([])
	// const [tabId,setTabId]=useState(1)
	const searchTxt=useRef('')
	const filterFrom=useRef('')
	const filterTo=useRef()
const columns=[
	{
		name: 'Name',
		selector: row => row.name,
		sortable: true,
		wrap: true,
	},
	{
		name: 'Contact',
		selector: row => row.contact,
		sortable: true,
		// minWidth:"20"
	},
    {
		name: 'No',
		selector: row => row.orderNo,
		sortable: true,
		
	},
    {
		name: 'Status',
		selector: row => row.status,
		sortable: true,
		// wrap:true,
		minWidth:"20"
	},
	{
		name: 'Date',
		selector: row => row.date,
		sortable: true,
	},
	{
		name: 'Location',
		selector: row => row.location,
		sortable: true,
	},
	{
		name: 'Total Cost',
		selector: row => row.totalCost,
		sortable: true,
	},
   
	{
		name: 'Items',
		selector: row => row.items,
		sortable: true,
	},
]
useEffect(()=>{
	function getOrders(){
	  setRunOrder(props.orders)
	  setOrders(props.orders)
	}
	getOrders()
},[props.orders])
const exportOrders=()=>{

const keys=["name","orderNo","paidVia","transactionRef","totalCost","deliveryCost","date"]
var csvBody=keys.map((ky)=>{
	return ky.charAt(0).toLocaleUpperCase()+ky.slice(1)
}).join(",")+"\n"
csvBody=csvBody+orders.map((row) => keys.map((k) => {
	return `"${row[k]}"`
}).join(",")).join("\n")
createCsv(csvBody,"orders.csv")
	/*
	const keys = Object.keys(orders[0]).filter((key)=>key!=="items")
	var csvBody=keys.map((ky)=>{
		return `"${ky}"`
	}).join(",")+"\n"
	csvBody=csvBody+orders.map((row) => keys.map((k) => {
		return `"${row[k]}"`
	}).join(",")).join("\n")*/

	
}
const search=(e)=>{
	const searchStr=searchTxt.current.value
	console.log("Ref ",searchStr)
	if(runOrders.length>0){
		const searched=runOrders.filter((item)=>{
			const checker=searcMatch(item.status,searchStr)||searcMatch(item.orderNo,searchStr)||
			searcMatch(item.customerLocation,searchStr)||searcMatch(item.name,searchStr)||
			searcMatch(item.contact,searchStr)||searcMatch(item.date,searchStr)||searcMatch(item.location,searchStr)
          return checker
		}
		)
		setOrders(searched)	
	}
}
const searcMatch=(value,search)=>{
return value.toLowerCase().includes(search.toLowerCase())
}
const filterOrders=()=>{
	console.log("From ",filterFrom.current,"To ",filterTo.current)
if(filterFrom.current.value.length===10&&filterTo.current.value.length===10){
	const fromDate=new Date(filterFrom.current.value.replaceAll("-","/")).getTime()
	const toDate=new Date(filterTo.current.value.replaceAll("-","/")).getTime()
	if(fromDate>toDate){
		setRunOrder([])
	    setOrders([])
		return
	}
		const filteredOrders=props.orders.filter((item)=>{
		const orderDate=new Date(item.date).getTime()
		if(orderDate>=fromDate&&orderDate<=toDate){
			return {...item}
		}
			return { }
	})
	setRunOrder(filteredOrders)
	setOrders(props.orders)
}
}
const cancelFilter=()=>{
	filterFrom.current.value=''
	filterTo.current.value=''
	setRunOrder(props.orders)
	setOrders(props.orders)
}
return(
    <div className="body-page">
		<div className="dt-actions dashboard-bg">
		   <div className="dash-item">
			{orders.length>0&&(<div className="icon-holder icons-mr-r1">
			   <CiExport onClick={exportOrders}/>
			</div>)}
			{props.orders.length>0&&(<div className="icon-holder">
			   <FiFilter onClick={filterOrders}/>
			</div>)}
			{props.orders.length>0&&(<div className="filter-dash-mr-1">
				<p className="filter-lb">From:</p>
					<input type="date" className="filter-order" ref={filterFrom}  placeholder="yyyy/mm/dd" />
			   </div>)}
			
			   {props.orders.length>0&&(<div className="filter-dash-mr">
			     <p className="filter-lb">To:</p>
					<input type="date" className="filter-order" ref={filterTo}  placeholder="yyyy/mm/dd" />
			   </div>)}
			   {props.orders.length>0&&(<div className="icon-holder icon-mr-l1">
			   <TbFilterOff onClick={cancelFilter}/>
			</div>)}
			</div>
			<div className="dash-item">
			{props.orders.length>0&&(<div className="order-dash-mrl">
					<input type="text" className="search-orders" onChange={search} ref={searchTxt} placeholder="Search here ..." />
			   </div>)}
			</div>
		</div>
        <DataTable
			columns={columns}
			data={orders}
            pagination
		/> 
    </div>
)
}
export default Orders