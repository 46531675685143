import React,{useEffect,useState} from "react"
//import DashBoardItem from "../components/DashBoardItem"
import ChartsOrders from "../components/ChartsOrders";
import ChartOthers from "../components/ChartOthers";
import axios from "axios";
const Home=()=>{
  // const token=useSelector(state=>state.auth.accessToken)
  const [orderAnalyse ,setOrderAnalyse]=useState({recieved:0,cancelled:0,placed:0,enroute:0})
  const [otherAnalyse,setOtherAnalyse]=useState({"shop":0,"user":0,"products":0})
    const [dashboradTitle,setDashboardTitle]=useState("Orders")
    useEffect(()=>{
      async function getAnalytics(){
        //"Order Delivered"
        const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/allOrders`)
        const countRecieved=data.filter((item)=>item.status==="Received"||item.status==="Order Delivered").length
        const countCancelled=data.filter((item)=>item.status==="Order Cancelled"||item.status==="System Cancelled").length
       const orderPlaced=data.filter((item)=>item.status==="Order Placed").length
       const riderEroute=data.filter((item)=>item.status==="Rider Enroute").length
       const orderAnalyse={recieved:countRecieved,cancelled:countCancelled,placed:orderPlaced,enroute:riderEroute}
       setOrderAnalyse(orderAnalyse)
      }
      getAnalytics()
    },[])
    useEffect(()=>{
      async function getAnalytics(){
        const shopResponse=await axios.get(`${process.env.REACT_APP_APIURL}/allLocations`)
        const usersResponse=await axios.get(`${process.env.REACT_APP_APIURL}/allRegistrations`)
        const productsResponse = await axios.get(`${process.env.REACT_APP_APIURL}/allProducts`)
        const products=productsResponse.data.length
        const shops=shopResponse.data.length
        const users=usersResponse.data.length
       const analyse= {"shop":shops,"user":users,"products":products}
        setOtherAnalyse(analyse)
      }
      getAnalytics()
    },[])
    const toggleDashboard=()=>{
      if(dashboradTitle==="Orders"){
       setDashboardTitle("Others")
       return
      }
      setDashboardTitle("Orders")
    }
return (
    <div className="home-page">
      <div className="dashboad-menu">
        <h3 className="simple-dashbord-title">
          <button className="btn-toggle" onClick={toggleDashboard}>{dashboradTitle==="Orders"?"Others":"Orders"}</button>
          </h3>
          <h3 className="simple-dashbord-title">
            {dashboradTitle}
          </h3>
        </div>
        {(dashboradTitle==="Orders" && <ChartsOrders delivered={orderAnalyse.recieved} canceled={orderAnalyse.cancelled} pending={orderAnalyse.placed} enroute={orderAnalyse.enroute}/>)} 
        {(dashboradTitle==="Others" && <ChartOthers
               customers={otherAnalyse.user}
              vendors={2}
              shops={otherAnalyse.shop}
               products={otherAnalyse.products}/>)}  
    </div>
)
}
export default Home