import { createSlice } from "@reduxjs/toolkit"
const AppSlice=createSlice({
    name:'app',
    initialState:{
      orders:[],
      products:[],
      feedback:{},
      price:{},
      order:{},
      geocode:{lat:0,lng:0}
    },
    reducers:{
    setOrders(state,action){
        state.orders=action.payload
    },
    setProducts(state,action){
      state.products=action.payload
    },
    setFeedback(state,action){
      state.feedback=action.payload
    },
    setPrice(state,action){
      state.price=action.payload
    },
    setOrder(state,action){
      state.order=action.payload
    },
    setGeocode(state,action){
      state.geocode=action.payload
    },
    resetGeocode(state){
      state.geocode={lat:0,lng:0}
    }
   }
   }
)
export const app=AppSlice.actions
export default AppSlice