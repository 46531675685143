import React,{useEffect,useState} from "react";
import { useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import ReviewItem from "../components/ReviewItem";
const Review=()=>{
    const feedback=useSelector(state=>state.app.feedback)
    const [comment,setComment]=useState('')
    const navigate=useNavigate()
    useEffect(()=>{
  
     function checkComment(){
      if(Object.entries(feedback).length===0){
        navigate("/feedbacks")
        return
    }
      if(feedback.additionalComments===null||feedback.additionalComments.length===0){
        setComment("No comment")
        return
       }
       setComment(feedback.additionalComments)
       return
     }
     checkComment()
    },[feedback,navigate])
return(<div className="body-page">
      <h3 className="rev-title">Review</h3>
    <div className="review-header">
        <ReviewItem title={`Order No`} itemValue={feedback.orderNo}/>
        <ReviewItem title={`Contact`} itemValue={feedback.phoneNumber}/>
        <ReviewItem title={`Rate`} itemValue={feedback.rate}/>
        <ReviewItem title={`Communication`} itemValue={feedback.communicationProcess}/>
        <ReviewItem title={`Ordering`} itemValue={feedback.orderProcess}/>
        <ReviewItem title={`Response`} itemValue={feedback.response}/>
    </div>
    <div className="review-describe">
       <h3 className="rev-title">Comment</h3>
       <div className="review-content-bd">
         <p className="contents-description">{`${comment}`}</p>
       </div>
    </div>
</div>)
}
export default Review