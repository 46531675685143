import React,{useEffect,useState,useMemo} from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
const UpdatePrice=()=>{
    const { shopId }=useParams()
    const navigate=useNavigate()
    const productPricing=useSelector(state=>state.app.price)
    const [productName,setProductName]=useState('')
    const [description,setDescription]=useState('')
    const [productNo,setProductId]=useState('')
    const [priceError,setPriceError]=useState('')
    const [productPrice,setProductPrice]=useState(0)
    const findPrice=useMemo(()=>{
        return productPricing
    },[productPricing])
    useEffect(()=>{
        function getPrice(){
            setProductName(findPrice.productName)
            setDescription(findPrice.productDescription)
            setProductId(findPrice.productId)
            setProductPrice(findPrice.productPrice)
        }
        getPrice()
    },[findPrice])
    const onSubmit=async(e)=>{
        e.preventDefault()
        if(productPrice.length<2){
            setPriceError("Invalid price")
            return
        }
        setPriceError("")
        try{
            await axios.put(`${process.env.REACT_APP_APIURL}/updatePrice`,
            {
                locationId:shopId,
                productId: productNo,
                price: productPrice
            }) 
            navigate(`/shop/details/${shopId}`)
        }
        catch(e){

        } 
        }
return(
<div>
    <form onSubmit={onSubmit} className="user-form-create">
        <div><h3 className="tb-title title-mg">Update Price</h3></div>
            <div className="sn-item-input">
                <label htmlFor='name' className='form-label'>Product Name:</label>
                <input type="text" className="input-value input-txt" id="name" value={productName} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='product_id' className='form-label'>Product ID:</label>
                <input type="text" className="input-value input-txt" id="product_id" value={productNo} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='product_desc' className='form-label'>Product Description:</label>
                <input type="text" className="input-value input-txt"  id="product_desc" value={description} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='product_desc' className='form-label'>Product Price:</label>
                <input type="number" className="input-value input-txt" id="product_desc" onChange={(e)=>{setProductPrice(e.target.value)}} value={productPrice}/>
                <p className="error">{priceError}</p>
            </div>
            <div className="sn-item-input">
                <button className="form-btn">Update</button>
            </div>
        </form>

    </div>
)
}
export default UpdatePrice