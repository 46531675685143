import { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
const GiveRole=()=>{
    const user=useSelector(state=>state.auth.user)
    const[userData,setUser]=useState({name:"",phoneNumber:"",email:""})
    useEffect(()=>{
        function setUserData(){
            const data={name:user.firstName+" "+user.lastName,phoneNumber:user.phoneNumber,email:user.emailAddress}
            setUser(data)
        }
        setUserData()
    },[user])
    const attachRole=async(role)=>{
        try{
            console.log("Console role ",role)
            const {data}=await axios.post(`${process.env.REACT_APP_APIURL}/changeRole`,{
                phoneNumber:userData.phoneNumber,
                role:role
                })
                console.log("Data recieved back ",data)
                alert(`${userData.name} given ${role}`)
        }
        catch(e){

        }

    }
return(<div>
        <h3 className="tb-title">Set role</h3>

       <div className="top-menu">
        <div className="menu-item">
            <h3 className="menu-title">Name</h3>
            <p className="menu-content">{userData.name}</p>
        </div>
        <div className="menu-item">
            <h3 className="menu-title">Email</h3>
            <p className="menu-content">{userData.email}</p>
        </div>
        <div className="menu-item">
            <h3 className="menu-title">Phone</h3>
            <p className="menu-content">{userData.phoneNumber}</p>
        </div>
     </div>
     <div className="header-item">
            <button className="action-btn mr-t mt-r" onClick={()=>attachRole("ADMIN")}>Admin</button>
            <button className="action-btn mr-t mt-r" onClick={()=>attachRole("LPG")}>LPG Cordinator</button>
            <button className="action-btn mr-t" onClick={()=>attachRole("SM")}>Sales Manager</button>
       </div>
   

</div>)
}
export default GiveRole