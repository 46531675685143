import React,{useState,useEffect} from "react";
import axios from "axios";
import DataTable from "react-data-table-component"
import { useDispatch,useSelector} from "react-redux";
import { vendor } from "../store/vendorSlice";
import { useNavigate } from "react-router-dom";
import { formatedDateTime } from "../utils/formatedDate";
const StockRequest=()=>{
    const [stocks,setStocks]=useState([])
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const role=useSelector(state=>state.auth.role)
    const Approve=({data})=>{
        const [format,setFormat]=useState({style:`tb-btn-stl`,label:`Approve`})
        return(
        <>
        {data.status==="OPEN"&&<button onClick={()=>{
               dispatch(vendor.stockData(data))
               navigate("/stocks/approval")
        }} className={format.style} onMouseOver={()=>setFormat({style:`tb-btn-stl-bolder`,label:`Approve`})}
         onMouseOut={()=>setFormat({style:`tb-btn-stl`,label:`Approve`})}>
          {format.label}
        </button>}
        {data.status!=="OPEN"&&<button className="tb-btn-stl" disabled>
            Approved
        </button>}
        </>)
      }
    const formatStr=(value)=>{
        if(value===null){
            return "Unavailable"
        }
        return value
    }
    useEffect(()=>{
        async function getStockRequests() {
            try{
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/allReplenishmentRequests`)
                if(data.length===0){
                    setStocks([])
                    return
                }
                const mapp_data=data.reverse().map((stock,index)=>{
                    return{
                        id:index+1,
                        shop:formatStr(stock.locationId),
                        date: formatedDateTime(stock.requestDate),
                        status:formatStr(stock.status),
                        vendorNumber:formatStr(stock.requestor),
                        productId:formatStr(stock.productId),
                        quantity:formatStr(stock.requestedQuantity),
                        approve:<Approve data={stock}/>
                    }
                })
                //We reverse before mapping to have the most recent records first on our list of requests
                setStocks(mapp_data)
            }
            catch(e){
             setStocks([])
            }
        }
        function checkPermissions(){
           const permission= role==='LPG' || role==='ADMIN' || role==='PM'
           if(!permission){
            navigate("/")
           }
        }
        checkPermissions()
        getStockRequests()
    },[navigate,role])
    const columns=[
        {
            name: 'No:',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Shop',
            selector: row => row.shop,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Requested On',
            selector: row => row.date,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Vendor',
            selector: row => row.vendorNumber,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Product',
            selector: row => row.productId,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            wrap:true,
        },
        {
            name: 'Action',
            selector: row => row.approve
        },
    ]
    return(
        <div className="body-page">
         <div className="dt-actions dashboard-bg">
		   <div className="dash-item">
             <h3 className="tb-dashboard">Stock Requests</h3>
			</div>
		 </div>
         <DataTable
			columns={columns}
			data={stocks}
            pagination
		/> 
        </div>
    )
}
export default StockRequest
     