import axios from "axios";
import React,{useState} from "react";
import { useParams } from "react-router-dom";
import Wait from "../components/Alerts/Wait";
const SaleRole=()=>{
    const {phone_number}=useParams()
    const roles=["Customer","Vendor","Both"]
    const [role,setRole]=useState("Vendor")
    const[alert,setAlert]=useState({label:"",isAlert:false,style:``})
    //alert-cl alert-error
    const changeSalesRole=async(e)=>{
        e.preventDefault()
        try{
            const payload={
                phoneNumber: phone_number,
                role
            }
            await axios.put(`${process.env.REACT_APP_APIURL}/updateRole`,payload)
            setAlert({label:"Role updated successfully ",isAlert:true,style:`alert-cl`})
        }
        catch(e){
            setAlert({label:"Role update error ",isAlert:true,style:`alert-error`})
        }
    }
    const cancelAlert=()=>{
        setAlert({label:"",isAlert:false,style:``})
    }
return(<div className="body-page">
 <form className="user-form-create" onSubmit={changeSalesRole}>
      <div className="sn-item-input">
        {alert.isAlert&&<Wait title={alert.label} color={alert.style} onCancel={cancelAlert}/>}
         <div><h3 className="tb-title title-mg">Sales Role</h3></div>
        <label htmlFor='name' className='form-label'>Change Role for {`(${phone_number})`}</label>
        <select onChange={(e)=>{setRole(e.target.value)}} className="input-value select-stl" value={role}>
              {roles.map((option, index) => {
                    return (
                        <option key={index}>
                            {option}
                        </option>
                    );
                })}
        </select>
    </div>
    <div className="sn-item-input">
        <button className="form-btn">Update</button>
    </div>
 </form>
</div>)
}
export default SaleRole