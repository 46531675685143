import React,{useEffect,useState} from "react";
import { useParams,useNavigate } from "react-router-dom"
import DataTable from "react-data-table-component";
import axios from "axios";
import { app } from "../store/appSlice";
import { useDispatch,useSelector } from "react-redux";
const ShopDetails=()=>{
    const { shopId } = useParams()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const role=useSelector(state=>state.auth.role)
    const[shopDetails,setShopDetails]=useState({})
    const[columns,setColumns]=useState([
        {
            name: 'No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Product ID',
            selector: row => row.productId,
            sortable: true,
        },
        {
            name: 'Product Quantity',
            selector: row => row.quantity,
            sortable: true,
        }
    ])
    const [prices,setPrice]=useState([])
    const Edit=({shopId,price})=>{
        return(<button onClick={()=>{
            dispatch(app.setPrice(price))
            navigate(`/prodprice/update/${shopId}`)
        }} className="tb-btns">
          Update
        </button>)
      }
      const editShop=()=>{
        navigate(`/add/shop/1/${shopId}`)
      }
      const stockShop=()=>{
         navigate(`/prodprice/${shopId}`)
      }
    
    useEffect(()=>{
        async function getShopDetails(){
            try{
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/locationById/${shopId}`)
                setShopDetails(data)
            }
            catch(e){
                console.log('Error ',e.message)
            }
        }
        getShopDetails()
    },[shopId])
    useEffect(()=>{
        async function getPricings(){
            try{    
            const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/getByLocationPricing/${shopId}/0713602423`)///
            const map_pricings=data.map((item,index)=>{
                return {
                    id:index+1,
                    productId:item.productId,
                    price:"Ksh "+item.productPrice.toLocaleString()+".0",
                    name:item.productName,
                    quantity:1,
                    edit:<Edit shopId={shopId} price={item}/>
                }
            })
            setPrice(map_pricings)
            }
            catch(e){
            }
        }
        getPricings()
    }
    ,[shopId])
    useEffect(()=>{
        if(role==="ADMIN"||role==="PM"){//
             setColumns([{
                name: 'No',
                selector: row => row.id,
                sortable: true,
            }, {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Product ID',
                selector: row => row.productId,
                sortable: true,
            },
            {
                name: 'Product Price',
                selector: row => row.price,
                sortable: true,
            },
            {
                name: 'Product Quantity',
                selector: row => row.quantity,
                sortable: true,
            },
             {
            name: 'Edit',
            selector: row => row.edit,
            sortable: true,
        }
        
        ])
        }
        /**
       
       , */
    },[role])
return(
    <div className="body-page">
        <div className="top-header">
            <div className="header-item">
                <h3 className="tb-title">Shop details</h3>
            </div>
            {role==="ADMIN" && <div className="header-item">
                <button className="action-btn mr-t mt-r" onClick={stockShop}>Stock Shop</button>
                <button className="action-btn mr-t" onClick={editShop}>Edit Shop</button>
            </div>}
          
        </div>
        <div className="top-menu">
            <div className="menu-item item-width-shop">
                <h3 className="menu-title">Name</h3>
                <p className="menu-content">{shopDetails.locationName}</p>
            </div>
            <div className="menu-item item-width-shop">
                <h3 className="menu-title">Description</h3>
                <p className="menu-content">{shopDetails.locationDescrition}</p>
            </div>
            <div className="menu-item item-width-shop">
                <h3 className="menu-title">Contact</h3>
                <p className="menu-content">{shopDetails.phoneNumber}</p>
            </div>
            <div className="menu-item item-width-shop">
                <h3 className="menu-title">Status</h3>
                <p className="menu-content">{shopDetails.status}</p>
            </div>
        </div>
        <div className="pricing-menu">
           <h3 className="tb-title mr-t">Inventory</h3>
           <DataTable
           	columns={columns}
               data={prices}
               pagination/>
        </div>
    </div>
)
}
export default ShopDetails