import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const EnableDiscount=()=>{
    const {phone_number}=useParams()
    const [firstName,setFirstName]=useState('')
    const [lastName,setLastName]=useState('')
    const [phoneNumber,setPhoneNumber]=useState('')
    const [staffNo,setStaffNo]=useState('')
    const [errors,setError]=useState([""])
    const navigate=useNavigate()

    useEffect(()=>{
        async function getCustomerDetails(){
            try{
                const { data }=await axios.get(`${process.env.REACT_APP_APIURL}/getByPhoneNumber/${phone_number}`)
                setFirstName(data.firstName)
                setLastName(data.lastName)
                setPhoneNumber(data.phoneNumber)
            }
            catch(e){
    
            }

        }
        getCustomerDetails()
    })
    const onSubmit=async(e)=>{
        e.preventDefault()
        try{
            if(staffNo.length===0){
                setError(["Staff number is required"])
                return
             }
             setError([""])
            const json_body={
                "isStaff":"1",
                "phoneNumber": `${phone_number}`,
                "staffNumber": staffNo.toUpperCase()
            }
            await axios.put(`${process.env.REACT_APP_APIURL}/updateStaffDetails`,json_body)
            navigate('/customers')
        }
        catch(e){

        }
        console.log('Phone number')
    }
    return(
        <div className="body-page">
            <form onSubmit={onSubmit} className="user-form-create">
            <div><h3 className="tb-title title-mg">Offer Discount</h3></div>
            <div className="sn-item-input">
                <label htmlFor='name' className='form-label'>First Name:</label>
                <input type="text" className="input-value input-txt" id="name" value={firstName} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='name' className='form-label'>Second Name:</label>
                <input type="text" className="input-value input-txt" id="name" value={lastName} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='name' className='form-label'>Phone Number:</label>
                <input type="text" className="input-value input-txt" id="name" value={phoneNumber} disabled/>
            </div>
            <div className="sn-item-input">
                <label htmlFor='name' className='form-label'>Staff No:</label>
                <input type="text" className="input-value input-txt" id="name" onChange={(e)=>{setStaffNo(e.target.value)}}/>
                <p className="error">{errors[0]}</p>
            </div>
            <div className="sn-item-input">
                <button className="form-btn">Save</button>
            </div>

            </form>

        </div>
    )

}
export default EnableDiscount