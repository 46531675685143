export const formatedDate=(date)=>{
    if(date!==null){
      const dateAlone=date.split("T")
      return dateAlone[0].replaceAll("-","/")
    }
    return "Unavailable"
  }
  export const formatedDateTime=(date)=>{
    if(date!==null){
      const dateAlone=date.split("T")
      return dateAlone[0].replaceAll("-","/")+" "+dateAlone[1]
    }
    return "Unavailable"
  }
  export const giveDate=()=>{
    const dateToday = new Date();
    const formatedDate=dateToday.toLocaleString('en-GB').split(",")
    return formatedDate[0].split("/").reverse().join("-")+formatedDate[1]
  }
