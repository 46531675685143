import React,{useState,useEffect} from "react";
import axios from "axios";
import Wait from "../components/Alerts/Wait";
import { useDispatch, useSelector } from "react-redux"
import {  useNavigate } from "react-router-dom";
import { vendor } from "../store/vendorSlice";
import { giveDate } from "../utils/formatedDate";
const ApproveStock=()=>{
const [quantity,setQuantity]=useState(0)
const [errors,setErrors]=useState([""])
const [alert,setAlert]=useState(false)
const [alertTxt,setAlertTxt]=useState("")
const stock=useSelector(state=>state.vendor.stocks)
const phoneNumber=useSelector(state=>state.auth.contact)
const role=useSelector(state=>state.auth.role)
const navigate=useNavigate()
const dispatch=useDispatch()
const cancelAlert=()=>{
    setAlert(!alert)
  }
const onSubmit=async(e)=>{
    e.preventDefault()
    try{
        const product=Number(quantity)
        if(product.length===0||product<1){//
            setErrors(["Provide correct product quantity"])
           return
        }
              setErrors([""])
              const dataBody={
                locationId:stock.locationId,
                productId:stock.productId,
                approvalDate:giveDate(),
                approvedQuantity:product,
                approvedBy:phoneNumber,
                status:"APPROVED",
                recordNo:stock.recordNo
            }
            setAlertTxt("Stock approved successfully")
            setAlert(true)
        const response=await axios.put(`${process.env.REACT_APP_APIURL}/effectReplenishment`,dataBody)
        dispatch(vendor.resetStock())
        console.log("Data response",response.data)
    }
    catch(e){
console.log("Exception ",e)
    }
}
useEffect(()=>{
function checkPermissions(){
    const permission= role==='LPG' || role==='ADMIN'
    if(!permission){
            navigate("/")
    }
}    
function checkStock(){
    if(Object.keys(stock).length===0){
       navigate("/stocks/requests")
    }
}
checkPermissions()
checkStock()
},[stock,navigate,role])
    return(<div className="body-page">
        <form className="user-form-create" onSubmit={onSubmit}>
            
             <div className="sn-item-input">

             {alert&&<Wait title={alertTxt} color={`alert-cl`} onCancel={cancelAlert}/>}
             <div className="card-form">
               <p>
                Requested to approve <b>{stock.requestedQuantity}</b> for product whose ID is <b>{stock.productId} </b>
                for vendor <b>{stock.requestor}</b> attached to shop <b>{stock.locationId}</b>
               </p>
             </div>
               <label htmlFor='name' className='form-label'>Appove Quantity</label>
               <input type="number" className="input-value input-txt input-right" id="name" onChange={(e)=>setQuantity(e.target.value)} value={quantity}/>
               <p className="error">{errors[0]}</p>
           </div>
           <div className="sn-item-input">
               <button className="form-btn">Approve</button>
           </div>
        </form>
       </div>)
}
export default ApproveStock