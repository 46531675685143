import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector} from "react-redux";
const Product=({ product })=>{
    const navigate=useNavigate()
    const role=useSelector(state=>state.auth.role)
    const editProduct=()=>{
       navigate(`/add/product/1/${product.productId}`)
    }
return(
<div className="product-item">
    <div className="product-head">
        <img src={`${product.image}`} alt={``} className="product-img"/>
    </div>
    <div className="product-details">
        <div className="product-name">
            <h2 className="product-title">
                {product.name}
            </h2>
         </div>
        <div className="product-description">
            <h3 className="product-id">{product.productId}</h3>
            <h2 className="contents-description">{product.description}</h2>
        </div>
        <div className="product-action">
            {role==="ADMIN" && <button className="action-btn" onClick={editProduct}>Edit</button> }
        </div>
    </div> 
</div>
)
}
export default Product