import React,{useState,useEffect} from "react";
import axios from "axios";
import { formatGraphDate } from "../utils/graphDate";
import {
    AreaChart,
    Area,
    XAxis,
    CartesianGrid,
    YAxis,PieChart, Pie, Cell,Tooltip
  } from "recharts";
const ChartOthers=({customers,vendors,shops,products})=>{
const [ratings,setRating]=useState([])
const [onBoardCustomers,setOnBoardCustomers]=useState([])
    useEffect(()=>{
      async function getRatings(){
       try{ 
         const { data }=await axios.get(`${process.env.REACT_APP_JGasReporting}/findCustomerRatings`)
         const chartData=data.map((item)=>{
           return{
             name:`${item.rate}`,
             value:item.rated
           }
         })
        setRating(chartData)
         }
         catch(e){
           setRating([])
       }
       }
       getRatings()
     },[])

     useEffect(()=>{
      async function getCustomers(){
       try{ 
         const { data }=await axios.get(`${process.env.REACT_APP_JGasReporting}/findCustomerJoinPerDay`)
         const onboards=data.length>7?data.splice(-7):data
         const chartData=onboards.map((item)=>{
           return{
             name:formatGraphDate(item.day),
             customers:item.customers
           }
         })
         setOnBoardCustomers(chartData)
         }
         catch(e){
          setOnBoardCustomers([])
       }
       }
       getCustomers()
     },[])
  
    const data = [
        { name: "Rated 1", value: 400 },
        { name: "Rated 2", value: 300 },
        { name: "Rated 3", value: 300 },
        { name: "Rated 4", value: 200 },
        { name: "Rated 5", value: 250 }
      ];
      
      const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
      const RADIAN = Math.PI / 180;
      const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
      }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };
      /*     {
          name: "21-03",
          customers: 40,
         
          amt: 2400
        }, */
return <>
    <div className="simple-dashboard-items">
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Products</h5>
            <h5 className="value-items">{products}</h5>
          </div>
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Shops</h5>
            <h5 className="value-items">{shops}</h5>
          </div>
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Customers</h5>
            <h5 className="value-items">{customers}</h5>
          </div>
          <div className="simple-db-item">
            <h5 className="title-item non-mr">Vendor</h5>
            <h5 className="value-items">{vendors}</h5>
          </div>
       </div>
       <div className="dashboard-mb">
       <h3 className="simple-dashbord-title">
          Charts 
        </h3>
       </div>
       <div className="charts-view">
         <div className="charts-view-item">
          <h4 className="chart-title">Average ratings</h4>
          <div className="pie-chart">
          <PieChart width={450} height={300}>
            <Pie
                data={ratings}
                cx={250}
                cy={140}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={130}
                fill="#8884d8"
                dataKey="value"
            >
                {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
             </Pie>
           <Tooltip />
          </PieChart>
          </div>
         </div>
         <div className="charts-view-item">
             <h4 className="chart-title">Customer onboarding for the last seven days entries</h4>
           <div className="pie-chart">
            <AreaChart
                width={490}
                height={250}
                data={onBoardCustomers}
                syncId="anyId"
                margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis label={{ value: 'No of Customers', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Area type="monotone" dataKey="customers" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
            </div>
         </div>
       </div>
</>
}
export default ChartOthers