import { createSlice } from "@reduxjs/toolkit"
const vendorSlice=createSlice({
    name:"vendor",
    initialState:{
        staffData:{},
        stocks:{}
    },
    reducers:{
      userData(state,action){
            state.staffData=action.payload
      } ,
      stockData(state,action){
        state.stocks=action.payload
  } ,
  resetUser(state){
    state.staffData={}
  },
  resetStock(state){
    state.stocks={}
  }
    }
})
export const vendor=vendorSlice.actions
export default vendorSlice