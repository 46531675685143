import React,{useState,useEffect} from "react";
import axios from "axios";
import Wait from "../components/Alerts/Wait";
const Settings=()=>{
    const [discount,setDiscount]=useState(0)
    const [errors,setErrors]=useState([""])
    const [alert,setAlert]=useState(false)
    const [alertTxt,setAlertTxt]=useState("")

    const onSubmit=async(e)=>{
        e.preventDefault()
        try{
            const disc=Number(discount)
            if(discount.length===0||disc===0){//
                setErrors(["Provide the discount"])
               return
            }
            setErrors([""])
            const json_body={
                "discountAmount": `${discount}`
            }
            setAlert(true)
            await axios.post(`${process.env.REACT_APP_APIURL}/createStaffOffer`,json_body)
            setAlertTxt("Staff offer saved")
            //navigate('/')
        }
        catch(e){
            setAlert(true)
            setAlertTxt("Staff offer saving failed")
            setErrors(["Error trying updating discount"])
        }
    }
    const cancelAlert=()=>{
      setAlert(!alert)
    }
    useEffect(()=>{
        async function getDiscount(){
            try{
                const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/activeDiscount/NAIROBI`)
                setDiscount(data.discountAmount)
            }
            catch(e){

            }
        }
        getDiscount()

    },[])
return(<div className="body-page">
 <form className="user-form-create" onSubmit={onSubmit}>
      <div className="sn-item-input">
      {alert&&<Wait title={alertTxt} color={`alert-cl`} onCancel={cancelAlert}/>}
      <div><h3 className="tb-title title-mg">Staffs offer allowed</h3></div>
        <label htmlFor='name' className='form-label'>{`Discount (KSh)`}</label>
        <input type="number" className="input-value input-txt input-right" id="name" onChange={(e)=>setDiscount(e.target.value)} value={discount}/>
        <p className="error">{errors[0]}</p>
    </div>
    <div className="sn-item-input">
        <button className="form-btn">Save</button>
    </div>
 </form>
</div>)
}
export default Settings